import { ActionInterface } from '../core/interfaces/action.interface';

export const TEXTAREA = {
  MIN_ROWS: 1,
  NORMAL_ROWS: 6,
  MAX_ROWS: 200,
};

export const ACTIONS: { [key: string]: ActionInterface } = {
  INSPECT: { id: Symbol(), name: 'Ver detalles' },
  CREATE: { id: Symbol(), name: 'Crear' },
  UPDATE: { id: Symbol(), name: 'Editar' },
  COPY: { id: Symbol(), name: 'Copiar' },
  DELETE: { id: Symbol(), name: 'Eliminar' },
  ARCHIVE: { id: Symbol(), name: 'Cambiar estado' },
  CONTRACT_DETAILS: { id: Symbol(), name: 'Ver contratos' },
  CUSTOMER_DETAILS: { id: Symbol(), name: 'Ver cliente' },
  SUBSCRIPTION_DETAILS: { id: Symbol(), name: 'Ver suscripción' },
  PRODUCT_DETAILS: { id: Symbol(), name: 'Ver producto' },
  OPERATION_DETAILS: { id: Symbol(), name: 'Ver operación' },
  VERSION_DOWNLOAD: { id: Symbol(), name: 'Descargar versión' },
  VERSION_RESTORE: { id: Symbol(), name: 'Restaurar versión' },
  VERSION_DELETE: { id: Symbol(), name: 'Eliminar versión' },
};

export const FIRESTORE_COLLECTION = {
  USERS: 'users',
  LOGS: 'logs',
};

export const LAST_LOG_SEEN_BY_MEDIA = 'lastLogSeenByMedia';

export enum ENTRYPOINTS {
  CREATE = 'create',
  COPY_FROM = 'copy-from',
  EDIT = 'edit',
  INSPECT = 'inspect',
  FORM = 'form',
  STRIPE = 'stripe',
}

export const MILLISECONDS_ON_ONE_SECOND = 1_000;
export const days = (quantity: number): number => quantity * hours(24);
export const hours = (quantity: number): number => quantity * minutes(60);
export const minutes = (quantity: number): number => quantity * seconds(60);
export const seconds = (quantity: number): number => quantity * MILLISECONDS_ON_ONE_SECOND;
