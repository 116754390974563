<div class="m-5 position-relative page-title">
  <div class="d-flex justify-content-between">
    <h1>{{ title }}</h1>
    <!-- Route -->
    <button
     *ngIf="buttonText && buttonLink"
      mat-raised-button
      class="c-button c-button--text-big c-button--mw c-button--br-10 py-2"
      color="primary"
      [routerLink]="buttonLink"
      [disabled]="!buttonEnabled"
    >
      {{ buttonText }} <i class="material-icons">add</i>
    </button>

    <!-- Custom function -->
    <button
     *ngIf="buttonTextSecondaryButton"
      mat-raised-button
      class="c-button c-button--text-big c-button--mw c-button--br-10 py-2"
      color="primary"
      (click)="clickSecondaryButtonEmitter()"
      [disabled]="!buttonEnabledSecondaryButton"
    >
      {{ buttonTextSecondaryButton }} <i class="material-icons">cloud_download</i>
    </button>

    <!-- Download list -->
    <button
      *ngIf="buttonDownloadList"
      mat-raised-button
      class="c-button c-button--text-big c-button--mw c-button--br-10 py-2"
      color="primary"
      (click)="downloadlist()"
      [disabled]="!downloadData?.length"
    >
      Descargar listado <i class="material-icons">downloading</i>
    </button>
  </div>
  <hr class="float-left hr-title">
</div>
