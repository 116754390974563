import { NgModule } from '@angular/core';

import { environment } from '../../../environments/environment';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { AngularFireModule as AngularFireModuleCompat } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';

const compatModules = [
  AngularFireModuleCompat,
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireDatabaseModule,
];

@NgModule({
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)), // TODO latest versions will use providers instead
    provideDatabase(() => getDatabase()), // TODO latest versions will use providers instead
    provideFirestore(() => getFirestore()), // TODO latest versions will use providers instead
    provideAuth(() => getAuth()), // TODO latest versions will use providers instead
    provideStorage(() => getStorage()), // TODO latest versions will use providers instead

    // Compat:
    AngularFireModuleCompat.initializeApp(environment.firebaseConfig),
    ...compatModules,
  ],
  exports: [...compatModules],
})
export class AngularFireModule {}
