// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    locale: 'es',
    env: 'pre',
    hmr: false,
    unlayerProjectId: 4553,
    firebaseConfig: {
        apiKey: 'AIzaSyDjjgfMg_7qGnHWnIVDBleJ5yodukAaBUg',
        authDomain: 'hipatia-pro.firebaseapp.com',
        databaseURL: 'https://hipatia-pro.firebaseio.com',
        projectId: 'hipatia-pro',
        storageBucket: 'hipatia-pro.appspot.com',
        messagingSenderId: '312617754142',
    },
     /******Widgets and Datasources deprecated******/
    widgetRendererAPI: 'https://widget-renderer-web-dev-wibdbssi3q-ew.a.run.app/',
    defaultDatasourceAPI: 'https://recommendation-prometeo.appspot.com/api/v3/widgets/report/current/top-views',
    deleteCacheAPI: {
        widgetBaseUrl: 'https://widget-renderer-web-dev-wibdbssi3q-ew.a.run.app/',
        deleteCacheWidget: 'web/widget/:mediaId/:widgetId',
        deleteCacheDatasource: 'datasource/:mediaId/:type/:datasourceId',
        deleteCacheModal: '/delete-cached-modal/:modalId',
        updateConfig: 'config/:mediaId',
        updatePrometeoConfig: 'prometeoconfig/:mediaId',
    },
    /*************************************/
    segmentsAPI: {
        baseURL: 'https://segments-api-staging-real-time-wibdbssi3q-ew.a.run.app/api',
        prefixGenerator: '/generator',
        prefixHistorical: '/historical/',
        prefixRealTime: '/real-time/',
        prefixArticles: 'articles/',
        prefixAuthor: 'author/',
        prefixPathName: 'path-name/',
        prefixReferer: 'referer/',
        prefixMetaKeyword: 'meta-keyword/',
        prefixMetaUrl: 'meta-url/',
        prefixOs: 'os/',
        prefixGeoCity: 'geo-city/',
        prefixGeoCountry: 'geo-country/',
        prefixGeoRegion: 'geo-region/',
        prefixSection: 'section-1/',
        prefixList: 'list/',
        prefixLayout: 'layout/',
    },
    admanagerSegmentsApi: {
        baseUrl: 'https://devserviceadmanager-wibdbssi3q-ew.a.run.app/ad-manager',
        // keyTargeting: 'test_pr_alayans_seg',
        keyTargeting: 'pr_alayans_seg',
    },
    /******Segment Historical deprecated******/
    rulesegmentAPI: {
        baseURL: 'https://rules-segment-api-staging-4ezanmnmka-ew.a.run.app',
        segmentHistoricalUsers: '/api/segment/historical/users/',
        segmentHistoricalCreate: '/api/segment/historical/:type/create/',
        segmentHistoricalUpdate: '/api/segment/historical/:type/update/',
        segmentHistoricalDelete: '/api/segment/historical/:type/delete/',
        updateHistoricalSegmentComponents: '/api/segment/historical/updateComponents',
        segmentHistoricalOperationUsers: '/api/segment/historical/operation/users/',
        segmentHistoricalOperationCreate: '/api/segment/historical/operation/createOperation',
        segmentHistoricalOperationUpdate: '/api/segment/historical/operation/updateOperation',
        segmentHistoricalOperationDelete: '/api/segment/historical/operation/deleteOperation',
        segmentsHistorical: '/api/segment/historical/segments/:mediaId',
        segmentsHistoricalApps: '/api/segment/historical/segments/app/:mediaId',
        updateImportedSegmentComponents: '/api/segment/imported/updateComponents',
        dataCleanRoomOperationSegmentUsers: '/api/data-clean-room/segment/operation/users',
        dataCleanRoomOperationSegmentCreate: '/api/data-clean-room/segment/operation/createOperation',
        dataCleanRoomOperationSegmentUpdate: '/api/data-clean-room/segment/operation/updateOperation',
        dataCleanRoomOperationSegmentDelete: '/api/data-clean-room/segment/operation/deleteOperation'
    },
     /*************************************/
    realTimeAPI: {
        baseURL: 'https://rt-wibdbssi3q-ew.a.run.app',
        concurrentUsersPath: '/rt/currents/user/:siteId',
        concurrentPageViewsPath: '/rt/currents/user/by-url/:siteId',
        concurrentUsersPathDevice: '/v2/rt/currents/user/:siteId?device_1=:d1&device_2=:d2',
        concurrentPageViewsPathDevice: '/v2/rt/currents/user/by-url/:siteId?device_1=:d1&device_2=:d2',
    },
    prometeoAppsAPI: 'https://prometeo-apps-api-dev-esvehatdmq-ew.a.run.app',
    hipatiaServicesApi: 'https://hipatia-services-api-stag-esvehatdmq-ew.a.run.app/api/',
    druidMetricsApi: 'https://druid-metrics-api-staging-wibdbssi3q-ew.a.run.app/',
    cloudFunctionsAPI: 'https://europe-west1-project-prometeo-v2.cloudfunctions.net/',
    metricsAPI: 'https://postgre-metrics-api-dev-wibdbssi3q-ew.a.run.app/',
    cloudfrontURL: 'https://dkumiip2e9ary.cloudfront.net/',
    emailActionsURL: 'https://hipatia-esvehatdmq-ew.a.run.app/login?email=',

    newsletterAPI: {
        baseUrl: 'https://newsletter-api-dev-osspk2fj2q-ew.a.run.app/api',
        prefixNewsletter: '/v1/newsletter',
        prefixSubscriptions: '/v1/subscriptions',
        createCampaign: '/create-campaign',
        sendCampaign: '/send-campaign',
        getCampaigns: '/campaign-list',
        createTag: '/create-tag',
        getTags: '/show-tags',
        getTemplates: '/show-templates',
        assignTemplate: '/add-template-to-campaign',
        assignTemplateAndReplicateCampaign: '/add-template-and-replicate-campaign',
        getCampaignContentById: '/show-campaign-content',
        getCampaignById: '/campaign',
        getAudiences: '/show-audiences',
        getAudiencesBySitePostgre: '/audiences-by-site/:siteId',
        createAudience: '/create-audiences',
        updateAudience: '/update-audiences',
        getAudienceById: '/get-audience',
        getAudienceByIdPostgre: '/audience/:audienceId',
        deleteAudience: '/:mediaId/delete-audiences/:idAudience',
        webhook: '/webhook/',
        updateSharedSites: '/site-audience/update',
        getSharedSites: '/site-audience/shared',
        prefixNewsletterV2: '/v2/newsletter',
        prefixNewsletterSyncV2: '/v2/newsletter-sync',
        createWebhookV2: '/create-webhook',
    },
    pushNotificationAPI: {
        baseUrl: 'https://lover-msg-api-dev-osspk2fj2q-ew.a.run.app',
        prefixPush: '/pushNotification',
        showTopics: '/show-topics',
    },
    scheduleNotification: {
        baseUrl: 'https://schedule-notification-dev-osspk2fj2q-ew.a.run.app/api/v1/',
        prefixSchedule: 'schedule-notification',
        multiple: '/multiple-topics',
    },
    triggerRedisActions: {
        baseUrl: 'https://actions-api-dev-wibdbssi3q-ew.a.run.app/api/v1',
    },
    triggerVersion: {
        baseUrl: 'https://trigger-version-staging-wibdbssi3q-ew.a.run.app/',
    },
    fcmCredentials: {
        baseURL: 'https://push-notification-fcm-pro-esvehatdmq-ew.a.run.app/api/v1/',
        prefixNotification: 'script-notification/',
        upload: 'config/:mediaId',
        uploadApp: 'app/config/:mediaId',
    },
    iosNotificationAPI: {
        baseUrl: 'https://ios-notification-api-wibdbssi3q-ew.a.run.app/api/v1',
        messagingDomain: 'https://push-notification-ios-dev-copmrxu3ca-ew.a.run.app',
        messagingUrl: 'https://push-notification-ios-dev-copmrxu3ca-ew.a.run.app/v1',
        prefixPackage: '/build-pushpackage',
        prefixTopics: '/topics/:mediaId',
        prefixSend: '/send/:mediaId/:topic',
    },
    tokenApps: {
        baseUrl: 'https://token-apps-api-staging-esvehatdmq-ew.a.run.app//v1/token/',
    },
    cloudFunctions: {
        parseDatalayer: 'https://europe-west1-hipatia-pro.cloudfunctions.net/parseDataLayer',
    },
    triggerApps: {
        urlImageClose:
            'https://firebasestorage.googleapis.com/v0/b/hipatia-pro.appspot.com/o/triggerImages%2Ficons8-close.png?alt=media&token=460bf663-609c-4ac1-a1b3-dcc6a28b6d7f',
    },
    endpoints: {
      pushNotifications: 'https://api-gateway-staging-esvehatdmq-ew.a.run.app/api/push-notifications/triggers',
    },
  thalia:{
    url: 'https://thaliapre-312617754142.europe-west1.run.app',
  }
};
