import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExcelService } from '../../services/excel.service';

export interface GenericTableTitleButton {
  label?: string;
  route?: string[];
  enabled: boolean;
}

@Component({
    selector: 'app-generic-table-title',
    templateUrl: './generic-table-title.component.html',
    styleUrls: ['./generic-table-title.component.scss'],
})
export class GenericTableTitleComponent {
    @Input() title: string;
    @Input() buttonText: string;
    @Input() buttonLink: string | string[];
    @Input() buttonEnabled: boolean;
    @Input() buttonTextSecondaryButton: string;
    @Input() buttonEnabledSecondaryButton: boolean;
    @Input() buttonDownloadList: boolean;
    @Input() downloadData: any[];
    @Input() excelFileName = 'informe';

    @Output() clickSecondaryButton = new EventEmitter<void>();

    constructor(private readonly excelService: ExcelService) {}

    clickSecondaryButtonEmitter(): void {
        this.clickSecondaryButton.emit();
    }

    downloadlist(): void {
        this.excelService.exportAsExcelFileOneSheet(this.downloadData, this.excelFileName);
    }
}
